import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { darken } from "polished"

export const DiscordContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #2f3136;
  color: #fff;
  border-radius: 4px;
  padding: 16px;
  box-sizing: border-box;
`

export const DiscordIcon = styled.img`
  display: block;
  width: 50px;
  height: 50px;
  margin: 0;
  margin-right: 16px;
  border-radius: 15px;
  background-color: #36393f;
`

export const DiscordTitle = styled.div`
  flex: 1 1 auto;
  min-width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
`

const DiscordVisitColor = "#43b581"

export const DiscordVisitButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  line-height: 20px;
  padding: 2px 20px;
  color: #fff;
  background-color: ${DiscordVisitColor};
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  margin-left: 6px;

  &:link,
  &:visited {
    color: #fff;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${darken(0.1, DiscordVisitColor)};
    color: #fff;
  }
`

export const DiscordWidget = props => {
  const { icon, name, url } = props

  return (
    <DiscordContainer>
      <DiscordIcon src={icon} />
      <DiscordTitle>{name}</DiscordTitle>
      <DiscordVisitButton href={url} target="_blank" rel="noopener">
        Visit
      </DiscordVisitButton>
    </DiscordContainer>
  )
}

DiscordWidget.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}
