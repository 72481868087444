import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { px } from "../styles/units"
import breakpoints from "../styles/breakpoints"
import v from "../styles/vars"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { useMetaCollectionOnline } from "../meta/collection/online"
import { HeadCard } from "../components/card"
import { DiscordWidget } from "../components/discord-widget"

const CommunityGrid = styled.div`
  display: grid;
  grid-gap: ${px(v.gap)};
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  ${breakpoints.downHorizontalNavigation} {
    grid-gap: ${px(v.mobileGap)};
  }
`

const OnlinePage = () => {
  const description = useMetaCollectionOnline()

  return (
    <Layout>
      <SEO title="Online Communities" description={description.plain} />

      <HeadCard title="Online Communities">
        <div dangerouslySetInnerHTML={{ __html: description.html }} />
      </HeadCard>

      <StaticQuery
        query={graphql`
          query Online {
            online: allOnlineJson(sort: { fields: name }) {
              edges {
                node {
                  icon
                  name
                  url
                  fields {
                    slug
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <CommunityGrid>
            {data.online.edges.map(edge => {
              const { icon, name, url, fields } = edge.node
              const { slug } = fields

              return (
                <DiscordWidget key={slug} icon={icon} name={name} url={url} />
              )
            })}
          </CommunityGrid>
        )}
      />
    </Layout>
  )
}

export default OnlinePage
